










































































  import { Component, Vue } from 'vue-property-decorator';
  import Table from '@/components/table';
  import MixinEdu from "@/views/education/Mixins/mixin-edu";

  @Component({
	  name: 'Year',
	  components: {Table},
	  mixins: [MixinEdu]
  })
  export default class Year extends Vue {
    constructor(props) {
      super(props);
    };
    $refs!: {table: Table}
    private AddDialogVisible:boolean = false;
    private dataSet:Array<{[key:string]: any}> = [];
    private tableHead:Array<{[key:string]: any}> = [
	    {
        property: 'name',
		    label: '年份名称',
		    width: 'auto',
		    align: 'left'
	    },
      {
        property: 'modifierName',
        label: '修改人',
        width: 'auto',
        align: 'left'
      },
      {
        property: 'modifyTime',
        label: '修改时间',
        width: 'auto',
        align: 'left'
      }
    ];
    private yearValue = '';
	  private rowId = '';

    private handleEdit(idx, row:any) {
      this.yearValue = row.val;
      this.rowId = row.id
      this.AddDialogVisible = true;
    }
		/** 删除 **/
    private async handleDelete(idx, row: never) {
      this.selectedArr = [row];
      this.batchDeleteHandle(this.$refs.table);
    };

    /**
     * 停用 && 启用
     */
    private handleChangeStatus(idx, row: never | any) {
      this.selectedArr = [row];
      this.batchChangeStatus((row.status == 1 ? 0 : 1), this.$refs.table)
    }

    /** 批量 停/启 用 **/
    private batchChangeStatusHandle(status) {
      this.batchChangeStatus(status, this.$refs.table);
    }

    private selectionChange(ev) {
			this.selectedArr = ev;
    };
    /** 新增年份 **/
    private async addYear() {
      if (this.$refs.table.dataSource.findIndex(i => i.name === this.yearValue) !== -1) {
        this.$message.warning('该年份已存在，请重新选择');
        return false;
      }
      let data = {
        id: this.rowId,
        typeCode: 'YEAR',
        name: this.yearValue,
        val: this.yearValue,
        isSystem: 1
      }
      const res: any = await this.axios.post('/system/dictionary/saveOrUpdate', data);
	    if (res.result) {
        // this.cusTableKey = Date.now();
        this.$refs.table.request();
        this.AddDialogVisible = !this.AddDialogVisible;
      }
    };

  }
